import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'rewards.benefits.heading',
    description: 'rewards benefits heading section',
    defaultMessage: 'Endless Extras',
  },
  description: {
    id: 'rewards.benefits.description',
    description: 'rewards benefits heading section description',
    defaultMessage:
      'Joining Starbucks® Rewards means unlocking access to benefits like quick and easy ordering, tasty Rewards and—yes, free coffee.',
  },

  benefit1Heading: {
    id: 'rewards.benefits.benefit1Heading',
    description: '1st rewards benefit heading',
    defaultMessage: 'Fun freebies',
  },
  benefit1Description: {
    id: 'rewards.benefits.benefit1Description',
    description: '1st rewards benefit description',
    defaultMessage:
      'Not only can you earn free food, drinks and more, look forward to a birthday treat on us.',
  },
  benefit1CtaDescription: {
    id: 'rewards.benefits.benefit1CtaDescription',
    description:
      '1st rewards benefit description for "learn more" call to action',
    defaultMessage: ' about fun freebies',
  },
  benefit2Heading: {
    id: 'rewards.benefits.benefit2Heading',
    description: '2nd rewards benefit heading',
    defaultMessage: 'Order & pay ahead',
  },
  benefit2Description: {
    id: 'rewards.benefits.benefit2Description',
    description: '2nd rewards benefit description',
    defaultMessage:
      'Master the art of ordering ahead with saved favorites and payment methods.',
  },
  benefit2CtaDescription: {
    id: 'rewards.benefits.benefit2CtaDescription',
    description:
      '2nd rewards benefit description for "learn more" call to action',
    defaultMessage: ' about ordering and paying ahead',
  },
  benefit3Heading: {
    id: 'rewards.benefits.benefit3Heading',
    description: '3rd rewards benefit heading',
    defaultMessage: 'Get to free faster',
  },
  benefit3Description: {
    id: 'rewards.benefits.benefit3Description',
    description: '3rd rewards benefit description',
    defaultMessage:
      'Earn Stars even quicker with Bonus Star challenges, Double Star Days and exciting games.',
  },
  benefit3CtaDescription: {
    id: 'rewards.benefits.benefit3CtaDescription',
    description:
      '3rd rewards benefit description for "learn more" call to action',
    defaultMessage: ' about getting free rewards faster',
  },
});
