import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import ContentColumn from '@starbucks-web/pattern-library/lib/components/content-column';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import Frap from 'shared/app/components/frap-with-messages';
import H1 from 'shared/app/components/h1';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { rewardsMessages } from 'shared/app/messages/rewards';

import { partnershipAuthentication } from '../../state/action-creators';

import messages from './messages';

const RedirectPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const configImagesUrl = useSelector(configImagesUrlSelector);

  useEffect(() => {
    setTimeout(() => {
      dispatch(partnershipAuthentication()).then((data) => {
        const { redirectURL } = data;
        window.location.href = redirectURL;
      });
    }, 5000);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {`${formatMessage(messages.redirectHeading)}: ${formatMessage(rewardsMessages.rewardsPartnershipsMarriott)}`}
        </title>
      </Helmet>
      <ContentColumn
        className="flex"
        innerClassName="flex justify-center items-center flex-column"
        size="sm"
        style={{ height: '100vh' }}
      >
        <H1 className="text-bold text-xl">
          {formatMessage(messages.redirectHeading)}
        </H1>
        <ImageLoader
          alt=""
          className="mt5 mb6"
          src={`${configImagesUrl}/rewards/loyalty-partnerships/marriott-bonvoy/redirect.png`}
          width="167.125px"
        />
        <Heading
          aria-live="polite"
          className="text-sm mx3 text-center mb7"
          tagName="h2"
        >
          {formatMessage(messages.redirectBody)}
        </Heading>
        <Frap aria-hidden loading tabindex="-1">
          &nbsp;
          {/* add content so Frap isn't tiny */}
        </Frap>
      </ContentColumn>
    </React.Fragment>
  );
};

export default RedirectPage;
